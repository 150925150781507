<template>
  <div class="comp-back-header">
    <div class="back-box" @click="$router.go(-1)"></div>
    <div>{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.comp-back-header {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 0.16rem;
  margin-bottom: 0.12rem;
  position: relative;
  .back-box {
    position: absolute;
    width: 0.25rem;
    height: 0.14rem;
    left: 0rem;
    top: 0rem;
    z-index: 3;
    background: url(../../assets/images/new/back-icon.png) no-repeat center;
    background-size: 100% 100%;
  }
}
</style>
