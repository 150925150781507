<template>
  <div class="comp-reg">
    <div class="reg-out">
      <div class="close-box" @click="SignInQX">
        <img
          style="width: 100%"
          src="@/assets/images/mobile/loginpopupgroup/icon17-new.png"
          alt="" />
      </div>
      <!-- <div class="login-title">
        <img style="width: 100%" src="@/assets/images/mobile/loginpopupgroup/icon15.png" alt="" />
      </div> -->
      <div class="reg-header">
        <div class="title">注册TMSKINS</div>
      </div>
      <div class="DZ-box-mobile">
        <el-form
          :model="RegisterForm"
          status-icon
          ref="RegisterForm"
          label-width="0px"
          class="demo-ruleForm">
          <el-form-item prop="mobile" label="">
            <div class="input-wrap">
              <!-- <img src="@/assets/images/mobile/loginpopupgroup/icon16.png" alt="" class="phone-icon" /> -->
              <el-input
                v-model="RegisterForm.name"
                placeholder="请输入昵称"></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="mobile" label="">
            <div class="input-wrap">
              <!-- <img src="@/assets/images/mobile/loginpopupgroup/icon2.png" alt="" class="phone-icon" /> -->
              <el-input
                v-model="RegisterForm.mobile"
                placeholder="请输入手机号"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="" class="YZM-box" prop="verify">
            <div class="input-wrap code">
              <!-- <img src="@/assets/images/mobile/loginpopupgroup/icon12.png" alt="" class="phone-icon" /> -->
              <el-input
                v-model="RegisterForm.verify"
                placeholder="请输入验证码"></el-input>
            </div>
            <div class="YZM" @click="GetYanZhengMa(1)">
              {{ !VerificationShow ? "发送" : "已发送" }}
              <van-count-down
                style="color: #fff; font-size: 0.1rem"
                v-show="VerificationShow"
                :time="time"
                class="CLR"
                format="ss"
                :auto-start="false"
                @finish="finish"
                ref="countDown" />
            </div>
          </el-form-item>
          <el-form-item prop="password" label="">
            <div class="input-wrap">
              <!-- <img src="@/assets/images/mobile/loginpopupgroup/icon12.png" alt="" class="phone-icon" /> -->
              <el-input
                v-model="RegisterForm.password"
                placeholder="请输入密码"></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="password1" label="">
            <div class="input-wrap">
              <!-- <img src="@/assets/images/mobile/loginpopupgroup/icon12.png" alt="" class="phone-icon" /> -->
              <el-input
                v-model="RegisterForm.password1"
                placeholder="请再次输入密码"></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="invite" label="">
            <div class="input-wrap">
              <!-- <img src="@/assets/images/mobile/loginpopupgroup/icon12.png" alt="" class="phone-icon" /> -->
              <el-input
                v-model="RegisterForm.invite"
                placeholder="请输入邀请码"></el-input>
            </div>
          </el-form-item>
          <div class="args">
            <div class="args-item">
              <img
                src="@/assets/images/mobile/loginpopupgroup/icon8-new.png"
                v-show="!argsStatus1"
                alt=""
                @click="argsStatus1 = true" />
              <img
                src="@/assets/images/mobile/loginpopupgroup/icon3-new.png"
                v-show="argsStatus1"
                alt=""
                @click="argsStatus1 = false" />
              我已阅读并同意<span
                @click="
                  $emit('closePopup');
                  $router.push('/Datapage?id=2');
                "
                >《用户协议》</span
              >和<span
                @click="
                  $emit('closePopup');
                  $router.push('/Datapage?id=3');
                "
                >《隐私政策》</span
              >
            </div>
          </div>
          <div class="btn-box">
            <div class="btn" @click="PostRegister">注册</div>
          </div>
          <div class="btn-box regtext" style="margin-top: 0.12rem">
            已有账号？<span style="color: #bfbfed" @click="logclick()"
              >去登录</span
            >
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Register,
  PostSendSms,
  SignIn,
  PersonalInfo,
  Arena_Box_List,
  Info,
} from "@/network/api.js";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      PWCode: true,
      argsStatus1: false,
      argsStatus2: false,
      VerificationShow: false,
      time: 60 * 1000,
      RegisterForm: {
        name: "",
        mobile: "",
        password: "",
        password1: "",
        verify: "",
        invite: "",
      },
      // RegisterForm: {
      //   mobile: "",
      //   password: "",
      //   verify: "",
      // },

      loginPopUpShow: false,
    };
  },
  created() {
    if (this.$route.query?.IvtCode) {
      this.RegisterForm.invite = this.$route.query?.IvtCode;
    }
  },
  methods: {
    ...mapMutations([
      "User",
      "GetIndividualsShow",
      "Leave",
      "GetRegisterShow",
      "GetSignInShow",
    ]),
    //取消登录
    SignInQX() {
      this.EmptySignInForm();
      this.closeFun();
      //   this.GetSignInShow(false);
    },
    //清空登录表单
    EmptySignInForm() {
      // this.RegisterForm.email = "";
      this.RegisterForm.pass = "";
    },
    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        localStorage.setItem("boxUserInfo", JSON.stringify(res.data.data));
        this.GetIndividualsShow(true);
      });
    },
    logclick() {
      this.$emit("logshow", (this.loginPopUpShow = true));
    },
    closeFun() {
      this.$emit("closePopup");
    },
    //注册操作
    PostRegister() {
      if (this.argsStatus1) {
        if (this.RegisterForm.password !== this.RegisterForm.password1) {
          this.$message({
            message: "两次输入密码不一致，请检查后重新输入",
            type: "error",
          });
          return false;
        }
        Register(
          this.RegisterForm.mobile,
          this.RegisterForm.password,
          this.RegisterForm.name,
          this.RegisterForm.verify,
          this.RegisterForm.invite
        ).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "注册成功",
              type: "success",
            });
            this.GetRegisterShow(false);
            this.EmptyForm();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          message: "请先勾选用户协议",
          type: "error",
        });
      }
    },
    //注册获取验证码
    GetYanZhengMa(num) {
      if (this.RegisterForm.mobile) {
        PostSendSms(this.RegisterForm.mobile, num).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "发送成功",
              type: "success",
            });
            this.VerificationShow = true;
            this.$refs.countDown.start();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
    },
    finish() {
      //重置倒计时
      this.VerificationShow = false;
      this.$refs.countDown.reset();
    },
  },
};
</script>

<style lang="scss">
.comp-reg {
  .reg-out {
    // display: flex;
    width: 100%;
    background: url(../../assets/images/mobile/loginpopupgroup/icon9-new.png);
    background-size: 100% 100%;
    position: relative;
    padding: 0.1rem 0.1rem 0.12rem 0.22rem;
    // padding: 0.5rem 0.29rem 0.29rem;
    box-sizing: border-box;
    .close-box {
      position: absolute;
      top: 0.1rem;
      right: 0.1rem;
      width: 0.12rem;
      height: 0.12rem;
    }
    .reg-header {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.16rem;
      color: #dfdbff;
      margin-bottom: 0.2rem;
    }
    .login-title {
      position: absolute;
      top: -0.43rem;
      left: 10%;
      width: 2.5rem;
    }
    .DZ-box-mobile {
      color: #fff;
      width: 100%;
      font-size: 0.14rem;
      padding: 0 0.66rem;
      .el-form-item__content {
        line-height: unset;
      }
      .demo-ruleForm {
        .el-form-item {
          margin-bottom: 0.1rem;
        }
        .input-wrap {
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          background: url(../../assets/images/mobile/loginpopupgroup/icon14-new.png)
            no-repeat;
          background-size: 100% 100%;
          // padding-left: 0.08rem;
          .phone-icon {
            width: 0.18rem;
          }
          .el-input__inner {
            border: none;
            background-color: unset;
            color: #eee;
            font-size: 0.1rem;
            height: 0.23rem;
            line-height: 0.23rem;
          }
          .el-input__inner::-webkit-input-placeholder {
            color: rgba(191, 191, 237, 0.5);
          }
        }
      }
      .YZM-box {
        position: relative;
        width: 100%;
        .YZM {
          padding: 0rem 0.16rem;
          border-left: 0.01rem solid #32325d;
          position: absolute;
          height: 0.11rem;
          right: 0rem;
          top: 0.05rem;
          color: #bfbfed;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.1rem;
        }
      }
      .args {
        width: 100%;
        margin-bottom: 0.25rem;
        .args-item {
          display: flex;
          align-items: center;
          color: #bfbfed;
          font-size: 0.1rem;
          white-space: nowrap;
          img {
            width: 0.1rem;
            flex-shrink: 0;
            margin-right: 0.06rem;
          }
        }
      }

      .btn-box {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-top: 0.16rem;

        .btn {
          width: 0.9rem;
          height: 0.26rem;
          background: url(../../assets/images/mobile/loginpopupgroup/icon10-new.png)
            no-repeat;
          background-size: 100% 100%;
          color: #d1d1d1;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.14rem;
        }
      }
      .regtext {
        font-weight: 300;
        font-size: 10px;
        color: #ffffff;
      }
    }
  }
}
</style>
