import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLazyload from "vue-lazyload"; //图片懒加载
import VueClipboard from "vue-clipboard2"; //复制文本

import Rem from "@/utils/rem.js"; //px转rem文件

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import Vant from "vant";
import "vant/lib/index.css";
// import MySocket from "./network/socket";
// Vue.use(MySocket);

Rem(375, 750, 100);
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Vant);
//复制文本
Vue.use(VueClipboard);

Vue.use(VueLazyload, {
  loading: require("@/assets/images/loading-svg/loading-bars.svg"),
  error: require("@/assets/images/other/none.jpg"),
});
import SingleBox from "@/components/common/Pages/SingleBox";
import Cprice from "@/components/common/Cprice";
import Cint from "@/components/common/Cint";
import Crarity from "@/components/common/Crarity";
import Cintegral from "@/components/common/Cintegral";
import MeanHeader from "@/components/common/MeanHeader.vue";

import BackHeader from "@/components/common/BackHeader.vue";
import MySelect from "@/components/common/MySelect.vue";
import Tabbar from "@/components/common/Tabbar.vue";
// 分页组件
import Pagination from "@/components/changepage/index.vue";
Vue.component("MySingleBox", SingleBox);

Vue.component("Cprice", Cprice);
Vue.component("Cint", Cint);
Vue.component("Crarity", Crarity);
Vue.component("Cintegral", Cintegral);
Vue.component("MeanHeader", MeanHeader);
Vue.component("BackHeader", BackHeader);
Vue.component("MySelect", MySelect);
Vue.component("Tabbar", Tabbar);
Vue.component("Pagination", Pagination);
// 解决定位显示为顶部问题
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
// 正式环境清除所有console.log
if (process.env.NODE_ENV === "production") {
  if (window) {
    window.console.log = function () {};
  }
}

//H5跳PC
var sUserAgent = navigator.userAgent.toLowerCase();
const flag = sUserAgent.match(
  /(ipod|iphone os|midp|ucweb|android|windows ce|windows mobile)/i
);
console.log(process.env.NODE_ENV === "production" ? "正式环境" : "test环境");
console.log(flag ? "移动端" : "PC端");
console.log(33333, location.href);
// location.href = "https://tmskins.com";
function checkUserAgent() {
  if (process.env.NODE_ENV === "production") {
    if (!flag) {
      // location.href = location.href.replace(/m\.tmskins\.com/g, "tmskins.com");
      location.href = "https://tmskins.com";
    } else {
      new Vue({
        router,
        store,
        render: (h) => h(App),
      }).$mount("#app");
    }
  } else {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
}
checkUserAgent();
