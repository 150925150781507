<template>
  <div id="app">
    <!-- <keep-alive exclude="VsBox"> -->
    <router-view />
    <!-- </keep-alive> -->
    <div style="opacity: 0; width: 0; height: 0" id="footer"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Blind_box_channel } from "@/network/api.js";
import { Info } from "@/network/api.js";
import Dialog from "vant/es/dialog";
export default {
  name: "",
  data() {
    return {
      isUserHandling: false,
    };
  },
  computed: {
    ...mapState(["global_info"]),
  },
  created() {
    console.log("APP.vue文件加载0812-13:05");
    this.init();
  },

  methods: {
    init() {
      this.checkVersionFun();
      this.checkUserDoneFun();
    },
    checkUserDoneFun() {
      let timer = null;
      addEventListener("click", () => {
        console.log("userclick");
        if (timer) {
          clearTimeout(timer);
        }
        this.isUserHandling = true;
        timer = setTimeout(() => {
          console.log("user10s");
          this.isUserHandling = false;
        }, 10000);
      });
    },

    checkVersionFun() {
      let Timer = setInterval(() => {
        try {
          Info().then((res) => {
            if (
              !this.isUserHandling &&
              this.global_info.version &&
              res?.data?.data?.version &&
              this.global_info.version !== res?.data?.data?.version
            ) {
              clearInterval(Timer);
              Dialog.alert({
                title: "标题",
                message: "网站已经更新，点击确认重新加载",
              }).then(() => {
                location.reload();
              });
            }
          });
        } catch (error) {}
      }, 60000);
    },
    //判断访问设备
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    PostBlind_box_channel(e) {
      Blind_box_channel(e).then((res) => {});
    },

    //加入房间
    JoinGroup(data) {
      this.AddInformation(data);
    },
    //对战开始
    AddStartGroup(data) {
      this.GetKaiShiVsData(data);
    },
    //对战结束
    AddEndGroup(data) {
      this.GetJieShuVsData(data);
    },
    ...mapActions(["AddInformation", "GetJieShuVsData", "GetKaiShiVsData"]),
  },
};
</script>

<style lang="scss">
.el-drawer__body {
  background-color: #20222b !important;
}

.bg-main {
  background-image: linear-gradient(90deg, #ecb105, rgb(0 0 0 / 0%)),
    linear-gradient(#ec7205, rgb(0 0 0 / 0%));
}

@import "./styles/bootstrap.css";
@import "./styles/common.scss";
@import "./assets/fonts/FZZ.css";

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.lv1 {
  background: url(./assets/images/new/open-bg1-new.png) no-repeat center;
  background-size: 100% 100%;
}

.lv2 {
  background: url(./assets/images/new/open-bg2-new.png) no-repeat center;
  background-size: 100% 100%;
}

.lv3 {
  background: url(./assets/images/new/open-bg3-new.png) no-repeat center;
  background-size: 100% 100%;
}

.lv4 {
  background: url(./assets/images/new/open-bg4-new.png) no-repeat center;
  background-size: 100% 100%;
}

.el-slider {
  opacity: 1;
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 8;
}

.el-slider__bar {
  height: 0.04rem !important;
  background: #373773 !important;
  border-radius: 0 !important;
}
.el-slider__runway {
  height: 0.04rem !important;
  margin: 0 !important;
  background: none !important;
}
.el-slider__button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.37rem !important;
  height: 100% !important;
  top: 0rem !important;
  // background: red;
}
.el-slider__button {
  border-radius: 0 !important;
  width: 100% !important;
  height: 0.14rem !important;
  opacity: 0;
  padding-left: 0.3rem;
  margin-left: 0.2rem;
}
.el-tooltip__popper {
  display: none !important;
}

* {
  margin: 0;
  padding: 0;
  background: url(/iconfont.svg);
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

.van-overlay {
  z-index: 999 !important;
  background-color: rgba(0, 0, 0, 0.9) !important;
}

body {
  background-color: #191919;
  color: #fff;
  font-family: "SansSerif";
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.Title-bg {
  width: 20rem;
  /*border: 1px solid #191b31;*/
  /*background-color: #131426;*/
  margin-left: -3rem;
  padding: 0.1rem;
}

.Title-title {
  position: relative;

  &::before {
    content: "";
    bottom: -0.2rem;
    left: 37.5%;
    position: absolute;
    width: 3rem;
    height: 50px;
    /*background-image: url("./assets/images/OpenBox/title.png");*/
    background-size: 100% 100%;
  }
}

.Title {
  text-align: center;

  p {
    display: inline-block;
    margin: 0 auto;
    font-size: 0.39rem;
    font-weight: 400;
    color: #cccccc;
    line-height: 1.66rem;
    text-transform: uppercase;
    /*text-shadow: 0 0 15px hsla(0, 0%, 100%, .5);*/
    padding: 20px 56px;
    // clip-path: polygon(13px 0, calc(100% - 13px) 0, 100% 50%, calc(100% - 13px) 100%, 13px 100%, 0 50%);
    position: relative;
    font-family: "FZZ";
  }
}

@keyframes zhuan {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*单行省略*/
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

/*多行文本省略*/
.twice {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
