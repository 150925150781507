import Vue from "vue";
import VueRouter from "vue-router";
import { Message } from "element-ui";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
//解决vue路由重复导航错误
const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("@/views/OutIndex/index.vue"),
    redirect: "/Home",
    children: [
      {
        path: "/User",
        name: "User",
        component: () => import("@/views/User/index.vue"),
        redirect: "/Highlight",
        children: [
          {
            path: "/About",
            name: "About",
            component: () => import("@/views/userInfo/index.vue"),
          },
          {
            path: "/Warehouse",
            name: "Warehouse",
            component: () => import("@/views/Warehouse/index.vue"),
          },
          {
            path: "/fusion",
            name: "fusion",
            component: () => import("@/views/Fusion/index.vue"),
          },
          {
            path: "/BagRecharge",
            name: "BagRecharge",
            component: () => import("@/views/BagRecharge/index.vue"),
          },
          {
            path: "/shareReward",
            name: "shareReward",
            component: () => import("@/views/shareReward/index.vue"),
          },
       
          {
            path: "/Tipspage",
            name: "Tipspage",
            component: () => import("@/views/Tipspage/index.vue"),
          },
        ],
      },
      {
        path: "/Datapage",
        name: "Datapage",
        component: () => import("../components/common/PublicFooter/Datapage.vue"),
      },
     
      {
        path: "/room",
        name: "room",
        component: () => import("@/views/Roll/roomDetail.vue"),
      },

      {
        //CSGO
        path: "/Home",
        name: "Home",
        component: () => import("@/views/Home/index.vue"),
      },

      {
        //福利房
        path: "/Roll",
        name: "Roll",
        component: () => import("@/views/Roll/index.vue"),
      },


      {
        //正常开箱
        path: "/OpeningBox",
        name: "OpeningBox",
        component: () => import("@/views/OpeningBox/index.vue"),
      },
      {
        path: "/kaijian",
        name: "kaijian",
        component: () => import("@/views/kaijian/index.vue"),
      },
  
    ],
  },

];

const router = new VueRouter({
  mode: "history",
  routes,
});

// 导航守卫
router.beforeEach((to, from, next) => {
  if (
    to.path === "/Warehouse" ||
    to.path === "/about" ||
    to.path === "/Extension" ||
    to.path === "/Recharge" ||
    to.path === "/CreatingRooms" ||
    to.path === "/WelfareInfo"
  ) {
    let token = localStorage.getItem("token");
    if (token === null || token === "") {
      next("/Home");
      Message({
        message: "您还未登陆，请先登陆!",
        type: "error",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
