<template>
  <div class="comp-selectbox">
    <div class="select-box point" @click="isShowFloat = !isShowFloat">
      <div class="input-value flex-s">
        {{ selectList.find((item) => item.value == value)?.label }}
      </div>
      <div class="row">
        <img
          src="../../assets/images/newuser/mb-rechange-pullicon.png"
          alt="" />
      </div>
      <div v-show="isShowFloat" class="float-box">
        <div
          @click.stop="changeSelect(item)"
          class="float-box-item flex-s"
          v-for="(item, index) in selectList"
          :key="index">
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShowFloat: false,
    };
  },
  methods: {
    changeSelect(item) {
      //   this.value = item.value;
      console.log(123, item);
      this.isShowFloat = false;
      this.$emit("input", item.value);
    },
  },
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    selectList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.comp-selectbox {
  .select-box {
    width: 0.89rem;
    height: 0.22rem;
    background: #08080f;
    border: 0.01rem solid #2f2f5e;
    position: relative;
    .input-value {
      width: 100%;
      height: 100%;
      line-height: 0.22rem;
      padding-left: 0.2rem;
      box-sizing: border-box;
      font-size: 0.1rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #c5bbed;
      overflow: hidden;
      white-space: nowrap;
    }
    .row {
      position: absolute;
      top: 0.02rem;
      right: 0.2rem;
      z-index: 1;
      img {
        width: 0.17rem;
        height: 0.16rem;
      }
    }

    .float-box {
      position: absolute;
      left: -0.01rem;
      background: #08080f;
      z-index: 99;
      border: 0.01rem solid #2f2f5e;
      border-top: 0;
      .float-box-item {
        width: 0.87rem;
        height: 0.22rem;
        line-height: 0.22rem;
        padding: 0 0.2rem;
        box-sizing: border-box;
        font-size: 0.1rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #c5bbed;
        white-space: nowrap;
        overflow: hidden;
      }
      .float-box-item:hover {
        background: #2f2f5e;
        color: #c5bbed;
      }
    }
  }
}
</style>
